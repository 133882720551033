// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconTwitter from "../../icons/IconTwitter.res.js";
import * as IconYoutube from "../../icons/IconYoutube.res.js";
import * as Routes_City from "../../../routes/common/Routes_City.res.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as IconFacebook from "../../icons/IconFacebook.res.js";
import * as IconLinkedin from "../../icons/IconLinkedin.res.js";
import * as FooterScss from "./Footer.scss";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as Routes_Services from "../../../routes/common/Routes_Services.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import LogoWhiteSvg from "Images/logo-white.svg";
import * as FooterNavigationSection from "./FooterNavigationSection.res.js";

var css = FooterScss;

function Footer$Footer(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("footer", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: Routes_Page.about,
                                        title: "About Us"
                                      },
                                      {
                                        href: Routes_Page.contact,
                                        title: "Contact Us"
                                      },
                                      {
                                        href: Routes_Page.termsOfUse,
                                        title: "Terms of Use"
                                      },
                                      {
                                        href: Routes_Page.privacyPolicy,
                                        title: "Privacy Policy"
                                      },
                                      {
                                        href: Routes_Page.sitemap,
                                        title: "Sitemap"
                                      }
                                    ],
                                    title: "Company"
                                  }),
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: Routes_City.ashburn,
                                        title: "Ashburn, VA"
                                      },
                                      {
                                        href: Routes_City.frankfurt,
                                        title: "Frankfurt, Germany"
                                      },
                                      {
                                        href: Routes_City.london,
                                        title: "London, UK"
                                      },
                                      {
                                        href: Routes_City.losAngeles,
                                        title: "Los Angeles, CA"
                                      },
                                      {
                                        href: Routes_City.dallas,
                                        title: "Dallas, TX"
                                      },
                                      {
                                        href: Routes_City.saoPaulo,
                                        title: "Sao Paulo, Brazil"
                                      },
                                      {
                                        href: Routes_City.index,
                                        title: "View All"
                                      }
                                    ],
                                    title: "Top Locations"
                                  }),
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: Routes_Provider.show("zenlayer"),
                                        title: "Zenlayer"
                                      },
                                      {
                                        href: Routes_Provider.show("equinix"),
                                        title: "Equinix"
                                      },
                                      {
                                        href: Routes_Provider.show("digital-realty"),
                                        title: "Digital Realty"
                                      },
                                      {
                                        href: Routes_Provider.show("qts"),
                                        title: "QTS Data Centers"
                                      },
                                      {
                                        href: Routes_Provider.show("coresite"),
                                        title: "Coresite"
                                      },
                                      {
                                        href: Routes_Provider.show("cyxtera"),
                                        title: "Cyxtera"
                                      },
                                      {
                                        href: Routes_Provider.index,
                                        title: "View All"
                                      }
                                    ],
                                    title: "Top Providers"
                                  }),
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: Routes_Services.Multicloud.index,
                                        title: "Multicloud"
                                      },
                                      {
                                        href: Routes_Services.BareMetal.index,
                                        title: "Bare Metal IaaS"
                                      },
                                      {
                                        href: Routes_Services.Colocation.index,
                                        title: "Colocation Hosting"
                                      },
                                      {
                                        href: Routes_Project.Cloud.$$new,
                                        title: "Custom Cloud"
                                      },
                                      {
                                        href: Routes_Project.Internet.$$new,
                                        title: "Internet Services"
                                      },
                                      {
                                        href: Routes_Project.Network.$$new,
                                        title: "Network (WAN)"
                                      }
                                    ],
                                    title: "Top Services"
                                  }),
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: Routes_Resource.category("checklists"),
                                        title: "Checklists"
                                      },
                                      {
                                        href: Routes_Resource.category("buyers-guides"),
                                        title: "Buyer's Guides"
                                      },
                                      {
                                        href: Routes_Resource.category("rfp-templates"),
                                        title: "RFP Templates"
                                      },
                                      {
                                        href: Routes_Resource.category("case-studies"),
                                        title: "Case Studies"
                                      },
                                      {
                                        href: Routes_Resource.category("whitepapers"),
                                        title: "White Papers"
                                      },
                                      {
                                        href: Routes_Resource.category("planning"),
                                        title: "Planning"
                                      },
                                      {
                                        href: Routes_Resource.category("real-estate"),
                                        title: "Real Estate"
                                      },
                                      {
                                        href: Routes_Resource.resourcesIndex,
                                        title: "View All"
                                      }
                                    ],
                                    title: "Resources"
                                  }),
                              JsxRuntime.jsx(FooterNavigationSection.make, {
                                    items: [
                                      {
                                        href: "tel:+1-833-471-7100",
                                        title: "+1 (833) 471-7100"
                                      },
                                      {
                                        href: "mailto:info@datacenters.com",
                                        title: "info@datacenters.com"
                                      }
                                    ],
                                    title: "Contact"
                                  })
                            ],
                            className: css.contentGrid
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("a", {
                                                        children: JsxRuntime.jsx("img", {
                                                              className: css.footerLogo,
                                                              alt: "Logo",
                                                              src: LogoWhiteSvg
                                                            }),
                                                        "aria-label": "Datacenters.com",
                                                        href: "/"
                                                      })
                                                }),
                                            className: css.logoSocialContainer
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("p", {
                                                  children: "\u00a9 " + (String(props.currentYear) + " Datacenters.com. All rights reserved."),
                                                  className: css.copyright
                                                }),
                                            className: css.copyrightContainer
                                          })
                                    ],
                                    className: css.footnoteLeft
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("ul", {
                                          children: [
                                            JsxRuntime.jsx("li", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Global.DCFacebookURL,
                                                        targetBlank: true,
                                                        className: css.socialElementBg,
                                                        children: JsxRuntime.jsx(IconFacebook.make, {
                                                              size: "SM",
                                                              color: "White"
                                                            })
                                                      }),
                                                  className: css.socialElement
                                                }),
                                            JsxRuntime.jsx("li", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Global.DCLinkedInURL,
                                                        targetBlank: true,
                                                        className: css.socialElementBg,
                                                        children: JsxRuntime.jsx(IconLinkedin.make, {
                                                              size: "SM",
                                                              color: "White"
                                                            })
                                                      }),
                                                  className: css.socialElement
                                                }),
                                            JsxRuntime.jsx("li", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Global.DCTwitterURL,
                                                        targetBlank: true,
                                                        className: Cx.cx([
                                                              css.socialElementBg,
                                                              css.socialElementTwitter
                                                            ]),
                                                        children: JsxRuntime.jsx(IconTwitter.make, {
                                                              size: "SM",
                                                              color: "White"
                                                            })
                                                      }),
                                                  className: css.socialElement
                                                }),
                                            JsxRuntime.jsx("li", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Global.DCYouTubeURL,
                                                        targetBlank: true,
                                                        className: Cx.cx([
                                                              css.socialElementBg,
                                                              css.socialElementYoutube
                                                            ]),
                                                        children: JsxRuntime.jsx(IconYoutube.make, {
                                                              size: "SM",
                                                              color: "White"
                                                            })
                                                      }),
                                                  className: css.socialElement
                                                })
                                          ],
                                          className: css.socialContainer
                                        }),
                                    className: css.footnoteRight
                                  })
                            ],
                            className: css.footnoteWrapper
                          })
                    ],
                    className: css.footer
                  }),
              className: css.wrapper
            });
}

var Footer = {
  make: Footer$Footer
};

function Footer$default(props) {
  return JsxRuntime.jsx(Footer$Footer, {
              currentYear: props.currentYear
            });
}

var $$default = Footer$default;

export {
  css ,
  Footer ,
  $$default as default,
}
/* css Not a pure module */
