// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconChevronDown from "../../icons/IconChevronDown.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FooterNavigationSectionScss from "./FooterNavigationSection.scss";

var css = FooterNavigationSectionScss;

function FooterNavigationSection(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  var screenSize = Hooks.useScreenSize();
  var canBeExpanded;
  switch (screenSize) {
    case "SM" :
        canBeExpanded = true;
        break;
    case "MD" :
    case "LG" :
        canBeExpanded = false;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: props.title,
                              className: css.header
                            }),
                        JsxRuntime.jsx("div", {
                              children: canBeExpanded ? JsxRuntime.jsx(IconChevronDown.make, {
                                      size: "SM",
                                      color: "White"
                                    }) : null,
                              className: isExpanded ? css.chevronExpanded : ""
                            })
                      ],
                      className: css.headerWrapper
                    }),
                JsxRuntime.jsx("ul", {
                      children: Belt_Array.map(props.items, (function (item) {
                              return JsxRuntime.jsx("li", {
                                          children: JsxRuntime.jsx(A.make, {
                                                href: item.href,
                                                children: item.title
                                              }),
                                          className: css.link
                                        }, item.href);
                            })),
                      className: Cx.cx([
                            css.linksWrapper,
                            canBeExpanded && !isExpanded ? css.linksWrapperCollapsed : ""
                          ])
                    })
              ],
              className: css.contentColumn,
              onClick: (function (param) {
                  if (canBeExpanded) {
                    return setIsExpanded(function (prevState) {
                                return !prevState;
                              });
                  }
                  
                })
            });
}

var make = FooterNavigationSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
