// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconTwitter(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Twitter";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M16 3.303a6.821 6.821 0 0 1-1.885.489 3.15 3.15 0 0 0 1.443-1.72 6.8 6.8 0 0 1-2.085.755 3.367 3.367 0 0 0-2.395-.981c-1.813 0-3.283 1.391-3.283 3.107 0 .243.03.48.085.708-2.728-.13-5.147-1.365-6.766-3.245a2.966 2.966 0 0 0-.444 1.56c0 1.078.58 2.03 1.46 2.586a3.415 3.415 0 0 1-1.487-.389v.04c0 1.505 1.131 2.76 2.633 3.046a3.476 3.476 0 0 1-1.483.053c.418 1.236 1.63 2.134 3.067 2.159a6.825 6.825 0 0 1-4.077 1.33c-.265 0-.526-.014-.783-.043a9.674 9.674 0 0 0 5.032 1.396c6.038 0 9.34-4.735 9.34-8.84 0-.135-.003-.27-.01-.403A6.463 6.463 0 0 0 16 3.303z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconTwitter;

export {
  make ,
}
/* Icon Not a pure module */
