// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconYoutube(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Youtube";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M8.22 2c.775.005 3.48.027 5.38.174.312.039.993.043 1.602.722.42.453.59 1.41.627 1.643l.008.052s.16 1.38.16 2.76v1.294c0 1.382-.16 2.762-.16 2.762s-.157 1.176-.636 1.694c-.608.677-1.29.682-1.602.722-1.9.146-4.607.172-5.384.177h-.263c-.46-.004-4.19-.048-5.39-.17-.357-.072-1.156-.051-1.765-.73-.478-.517-.635-1.693-.635-1.693s-.16-1.38-.16-2.761l.001-1.483c.013-1.315.16-2.572.16-2.572s.156-1.177.635-1.695c.61-.68 1.29-.683 1.601-.722 1.9-.147 4.605-.17 5.381-.172zM6.002 5v6l5-3z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconYoutube;

export {
  make ,
}
/* Icon Not a pure module */
