// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconFacebook(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Facebook";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M9.333 5.333V3.746c0-.717.159-1.08 1.271-1.08H12V0H9.67C6.818 0 5.876 1.308 5.876 3.554v1.78H4V8h1.875v8h3.458V8h2.35L12 5.333Z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "evenodd"
                  })
            });
}

var make = IconFacebook;

export {
  make ,
}
/* Icon Not a pure module */
