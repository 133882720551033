// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconLinkedin(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Linkedin";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M3.76 5.873v10.809H.272V5.873H3.76zm7.44.02c3.064 0 3.633 2.18 3.633 5.017v5.772H11.81v-5.387c-.01-1.173-.128-2.528-1.571-2.528-1.577 0-1.814 1.334-1.814 2.704v5.21H5.4L5.394 6.15h2.9v1.442h.04c.405-.827 1.391-1.698 2.867-1.698zM2.024.5c1.115 0 2.022.875 2.022 1.95 0 1.074-.907 1.949-2.022 1.949C.9 4.399 0 3.524 0 2.449 0 1.375.901.5 2.023.5z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconLinkedin;

export {
  make ,
}
/* Icon Not a pure module */
